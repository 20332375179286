import React from "react";

const ContactArea = () => {
	return (
		<>
			<div className="contact-area space">
				<div className="container">
					<div className="row gy-4 justify-content-center">
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<div className="contact-info_icon">
									<i className="fas fa-map-marker-alt" />
								</div>
								<h6 className="contact-info_title">Adresse</h6>
								<p className="contact-info_text">Ammerseestr. 15, 82239 Alling-Biburg</p>
								<p className="contact-info_text">Geschäftsführer: Benedikt Heimrath, Matthias Heimrath</p>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<div className="contact-info_icon">
									<i className="fas fa-phone-alt" />
								</div>
								<h6 className="contact-info_title">Telefon</h6>
								<p className="contact-info_text">
									<p href="tel:+49814144496">+49 81 41-4 44 96</p>
									<p href="mailto:michael.mitc@example.com">info@heimrath-gmbh.de</p>
								</p>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<div className="contact-info_icon">
									<i className="fas fa-clock" />
								</div>
								<h6 className="contact-info_title">Öffnungszeiten</h6>
								<p className="contact-info_text">Montag bis Donnerstag: 07:30 - 12:00 und 13:00 - 17:30</p>
								<p className="contact-info_text">Freitag: 07:30 - 12:00</p>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<div className="contact-info_icon">
									<i class="fas fa-gavel" />
								</div>
								<h6 className="contact-info_title">Sonstiges</h6>
								<p className="contact-info_text">Handelsregister München HRB90490</p>
								<p className="contact-info_text">Steuernummer 117/128/20235</p>
								<p className="contact-info_text">Umsatzsteuer-ID DE128245719</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="title-area">
				{/* <span className="sub-title">Unser Service</span> */}
				{/* <h2 className="sec-title">
							Ihr weg zu uns{" "}
							<img className="title-bg-shape" src="assets/img/bg/title-bg-shape.png" alt="Fixturbo" />
						</h2> */}
			</div>
			<div className="space-bottom">
				<div className="container">
					<div className="map-sec">
						<iframe
							src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ammerseestr.%2015,%2082239%20Alling-Biburg+(Heimrath%20Wohnmobil%20und%20KfZ%20-Service%20GmbH)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
							allowFullScreen=""
							loading="lazy"
							title="address"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactArea;
