import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const AboutTwo = () => {
	return (
		<div className="space-top">
			<div className="container">
				<div className="row">
					<div className="col-xxl-7 col-xl-6">
						<div className="about-thumb2 mb-40 mb-xl-0">
							<div className="about-img-1">
								<img src="assets/img/normal/about_1.jpg" alt="Heimrath" />
							</div>
							<div className="about-img-2">
								<img src="assets/img/normal/about_2.jpg" alt="Heimrath" />
							</div>
							<div className="about-counter-wrap jump-reverse">
								<img src="assets/img/icon/about_icon2-1.svg" alt="Heimrath" />
								<h3 className="about-counter">
									<TrackVisibility once>
										{({ isVisible }) =>
											isVisible && (
												<span className="counter-number">
													<CountUp delay={0} start={0} end={2500} separator="." />+
												</span>
											)
										}
									</TrackVisibility>
								</h3>

								<h4 className="about-counter-text">Kunden, die uns Vertrauen</h4>
							</div>
							{/* <div className="about-year-wrap2 movingX">
								<div className="about-year-grid-wrap">
									<div className="icon">
										<img src="assets/img/icon/about_icon2-2.png" alt="Heimrath" />
									</div>
									<h3 className="about-counter">
										<span className="counter-number">35</span>+
									</h3>
								</div>
								<h4 className="about-year-text">Jahre Erfahrung</h4>
							</div> */}
						</div>
					</div>
					<div className="col-xxl-5 col-xl-6">
						<div className="about-content-wrap">
							<div className="title-area mb-30">
								<span className="sub-title">Über uns</span>
								<h2 className="sec-title">
									Langjährige Expertise, vertrauensvoller Service{" "}
									<img
										className="title-bg-shape shape-center"
										src="assets/img/bg/title-bg-shape.png"
										alt="Heimrath"
									/>
								</h2>
								<p className="sec-text">
									Mit langjähriger Erfahrung in der Automobilbranche und über 2.500 treuen Kunden, steht
									unsere Werkstatt für Qualität und Zuverlässigkeit. Jährlich bearbeiten wir etwa 3.500
									Aufträge, wobei jedes Fahrzeug mit höchster Sorgfalt und Professionalität behandelt wird.
								</p>
							</div>
							<div className="about-feature-wrap style-shadow">
								<div className="icon">
									<img src="assets/img/icon/about_icon2-3.svg" alt="Heimrath" />
								</div>
								<div className="about-feature-wrap-details">
									<h5 className="about-feature-title">Umfassende Dienstleistungen</h5>
									<p className="about-feature-text">
										Von der Inspektion, über Werkstattersatzwagen, bis zur Hauptuntersuchung bieten wir alle
										Services unter einem Dach.{" "}
									</p>
								</div>
							</div>
							<div className="about-feature-wrap style-shadow">
								<div className="icon">
									<img src="assets/img/icon/about_icon2-4.svg" alt="Heimrath" />
								</div>
								<div className="about-feature-wrap-details">
									<h5 className="about-feature-title">Professionelle Partnerschaften</h5>
									<p className="about-feature-text">
										Als Dometic Servicepartner und Webasto Einbaupartner garantieren wir hochwertige Lösungen
										für Ihre spezifischen Bedürfnisse.{" "}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutTwo;
